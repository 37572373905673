import { ControlData } from "./data";
import "../../utils/datetime";
import moment from "moment";

export class ControlTime extends ControlData {
	make_input() {
		var me = this;
		super.make_input();
		this.$input.datepicker({
			language: "en",
			timepicker: true,
			onlyTimepicker: true,
			timeFormat: "hh:ii:ss",
			startDate: bcore.datetime.now_time(true),
			onSelect: function () {
				// ignore micro seconds
				if (moment(me.get_value(), 'hh:mm:ss').format('HH:mm:ss') != moment(me.value, 'hh:mm:ss').format('HH:mm:ss')) {
					me.$input.trigger('change');
				}
			},
			onShow: function () {
				$('.datepicker--button:visible').text(__('Now'));
			},
			keyboardNav: false,
			todayButton: true
		});
		this.datepicker = this.$input.data('datepicker');
		this.datepicker.$datepicker
			.find('[data-action="today"]')
			.click(() => {
				this.datepicker.selectDate(bcore.datetime.now_time(true));
			});
		this.refresh();
	}

	set_input(value) {
		super.set_input(value);
		if (value
      && ((this.last_value && this.last_value !== this.value)
        || (!this.datepicker.selectedDates.length))) {

			var date_obj = bcore.datetime.moment_to_date_obj(moment(value, 'HH:mm:ss'));
			this.datepicker.selectDate(date_obj);
		}
	}

	refresh_input() {
		super.refresh_input();

		let timezone = this.get_timezone();

		if (timezone && this.disp_status != "None") {
			this.set_secondary_label(timezone);
		}
	}

	get_timezone() {
		return bcore.sys_defaults.time_zone;
	}
}

bcore.ui.form.ControlTime = ControlTime