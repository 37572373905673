import Awesomplete from 'awesomplete';
import { ControlData } from './data';


export class ControlAutocomplete extends ControlData {
	make_input() {
		super.make_input();
		this.setup_awesomplete();
		this.set_options();
	}

	set_options() {
		if (this.df.options) {
			let options = this.df.options || [];
			this._data = this.parse_options(options);
		}
	}

	get_awesomplete_settings() {
		var me = this;
		return {
			minChars: 0,
			maxItems: this.df.max_items || 99,
			autoFirst: true,
			list: this.get_data(),
			data: function(item) {
				if (!(item instanceof Object)) {
					var d = { value: item };
					item = d;
				}

				return {
					label: item.label || item.value,
					value: item.value
				};
			},
			filter: function(item, input) {
				let hay = item.label + item.value;
				return Awesomplete.FILTER_CONTAINS(hay, input);
			},
			item: function(item) {
				var d = this.get_item(item.value);
				if (!d) {
					d = item;
				}

				if (!d.label) {
					d.label = d.value;
				}

				var _label = me.translate_values ? __(d.label) : d.label;
				var html = '<strong>' + _label + '</strong>';
				if (d.description) {
					html += '<br><span class="small">' + __(d.description) + '</span>';
				}

				return $('<li></li>')
					.data('item.autocomplete', d)
					.prop('aria-selected', 'false')
					.html('<a><p>' + html + '</p></a>')
					.get(0);
			},
			sort: () => {
				return 0;
			}
		};
	}

	/**
	 * Used to find any offset that could cause the awesomplete dropdown to detach from
	 * input since we are fixing its position on screen.
	 */
	find_transform() {
		let $el = this.$wrapper;
		this.transform_offset = { top: 0, left: 0 };
		let count = 0; // escape hatch
		while ($el && $el.length > 0 && count < 400) {
			count++;
			const el = $el[0];
			const style = getComputedStyle(el);
			const transform = style.transform;
			if (transform && transform != "none") {
				// If we have a transform assume translateX and translateY
				// as they would be the most common for centering content on the screen
				// So our offset would be top and left bounds of this element.

				const transform_value = transform.match(/-?\d+(\.\d+)?/g);
				const dx = parseFloat(transform_value[4]);
				const dy = parseFloat(transform_value[5]);

				// Skip zero transformed elements
				if (dx != 0 || dy !=0) {
					this.transformed_parent = el;
					break;
				}
			}

			$el = $el.parent();

			if ($el.is('body')) {
				break;
			}
		}
	}

	adjust_dropdown() {
		const me = this;
		if (me.autocomplete_open) {
			if (me.is_visible) {
				const wrapperBounds = me.$wrapper[0].getBoundingClientRect();
				const top = wrapperBounds.bottom;
				const left = wrapperBounds.left;
				if (this.transformed_parent) {
					this.transform_offset = this.transformed_parent.getBoundingClientRect();
				}
				const css = {
					"position": "fixed",
					"top": top - me.transform_offset.top,
					"left": left - me.transform_offset.left,
					"width": me.$wrapper.width()
				};
				me.$wrapper.find('ul').css(css);
			} else {
				// When user scrolls dropdown out of its parent scrollable view while open
				// just move it completely out of the viewport so we don't have a floating
				// dropdown.
				me.$wrapper.find('ul').css({
					"top": -100000
				});
			}

			window.requestAnimationFrame(this.adjust_dropdown.bind(this));
		} else {
			this.intersectObserver.disconnect();
		}
	}

	setup_awesomplete() {
		this.awesomplete = new Awesomplete(
			this.input,
			this.get_awesomplete_settings()
		);

		$(this.input_area)
			.find('.awesomplete ul');

		// monitor this control visibility to allow hiding dropdown
		// when user scrolls input away from scrollable view.
		this.viewportRoot = this.$wrapper.scrollParent().get(0);
		this.intersectObserver = new IntersectionObserver((entries) => {
			this.is_visible = entries[0].isIntersecting === true;
		}, {
			root: this.viewportRoot
		});

		this.$input.on(
			'input',
			bcore.utils.debounce(() => {
				this.awesomplete.list = this.get_data();
			}, 500)
		);

		this.$input.on('focus', () => {
			if (!this.$input.val()) {
				this.$input.val('');
				this.$input.trigger('input');
			}
		});

		this.$input.on('awesomplete-selectcomplete', () => {
			this.$input.trigger('change');
		});

		this.$input.on("awesomplete-open", () => {
			if ( !this.transform_init ) {
				this.transform_init = true;
				this.find_transform();
			}
			this.autocomplete_open = true;
			// Track visibility. Needs to be reattached on every open event.
			this.intersectObserver.observe(this.$input.get(0));
			// update dropdown position on animation frame(as fast as possible)
			window.requestAnimationFrame(this.adjust_dropdown.bind(this));
		});

		this.$input.on("awesomplete-close", function () {
			this.autocomplete_open = false;
		});
	}

	validate(value) {
		if (this.df.ignore_validation) {
			return value || '';
		}
		let valid_values = this.awesomplete._list.map(d => d.value);
		if (!valid_values.length) {
			return value;
		}
		if (valid_values.includes(value)) {
			return value;
		} else {
			return '';
		}
	}

	parse_options(options) {
		if (typeof options === 'string') {
			options = options.split('\n');
		}
		if (typeof options[0] === 'string') {
			options = options.map(o => ({ label: o, value: o }));
		}
		return options;
	}

	get_data() {
		return this._data || [];
	}

	set_data(data) {
		data = this.parse_options(data);
		if (this.awesomplete) {
			this.awesomplete.list = data;
		}
		this._data = data;
	}
}

bcore.ui.form.ControlAutocomplete = ControlAutocomplete;
