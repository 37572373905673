export class ControlDynamicLink extends bcore.ui.form.ControlLink {
	get_options() {
		let options = '';
		if(this.df.get_options) {
			options = this.df.get_options();
		}
		else if (this.docname==null && cur_dialog) {
			//for dialog box
			options = cur_dialog.get_value(this.df.options);
		}
		else if (!cur_frm) {
			const selector = `input[data-fieldname="${this.df.options}"]`;
			let input = null;
			if (cur_list && cur_list.filter_area) {
				// for list page
				input = cur_list.filter_area.standard_filters_wrapper.find(selector);
				if (!input.val()) {
					let label = bcore.meta.get_label(this.df.doctype, this.df.options);
					bcore.msgprint(__(`Please select <b>${label}</b> before selecting <b>${this.df.label}</b>`));
				}
			}
			if (cur_page) {
				input = $(cur_page.page).find(selector);
			}
			if (input) {
				options = input.val();
			}
		}
		else {
			options = bcore.model.get_value(this.df.parent, this.docname, this.df.options);
		}

		if (bcore.model.is_single(options)) {
			bcore.throw(__(`${options.bold()} is not a valid DocType for Dynamic Link`));
		}

		return options;
	}
}

bcore.ui.form.ControlDynamicLink = ControlDynamicLink;