export class ControlHTML extends bcore.ui.form.Control {

	get default_rowspan() { return 3; }
	get is_wide() { return true; }

	make() {
		super.make();
		this.disp_area = this.wrapper;
	}
	refresh_input() {
		var content = this.get_content();
		if(content) this.$wrapper.html(content);
	}
	get_content() {
		var content = this.df.options || "";
		try {
			return bcore.render(content, this);
		} catch (e) {
			return content;
		}
	}
	html(html) {
		this.$wrapper.html(html || this.get_content());
	}
	set_value(html) {
		if(html.appendTo) {
			// jquery object
			html.appendTo(this.$wrapper.empty());
		} else {
			// html
			this.df.options = html;
			this.html(html);
		}
	}
}

bcore.ui.form.ControlHTML = ControlHTML;