import { ControlData } from "../controls/data";


export class ControlText extends ControlData {

	get default_rowspan() { return 4; }
	get is_wide() { return true; }
	get html_element () { return "textarea" }
	get horizontal() { return false }

	make_wrapper() {
		super.make_wrapper();
		this.$wrapper.find(".like-disabled-input").addClass("for-description");
	}
	make_input() {
		super.make_input();
		this.$input.addClass("flex-fluid")
	}
}

export class ControlSmallText extends ControlText{
	get default_rowspan() { return 4; }
	get is_wide() { return true; }

	make_input() {
		super.make_input();
		this.$input.addClass("flex-fluid")
	}
}

export class ControlLongText extends ControlText {
	get default_rowspan() { return 4; }
	get is_wide() { return true; }
}

bcore.ui.form.ControlText = ControlText;
bcore.ui.form.ControlLongText = ControlLongText;
bcore.ui.form.ControlSmallText = ControlSmallText;