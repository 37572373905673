export class ControlCheck extends bcore.ui.form.ControlData {
	get input_type() {
		return "checkbox";
	}

	make_wrapper() {
		this.$wrapper = $(`<div class="form-group bcore-control flex-col jc-center pl-1h min-h-40px">
			<div class="checkbox">
				<label class="d-flex flex-row gap-1h ai-center">
					<span class="input-area"></span>
					<span class="disp-area"></span>
					<span class="label-area ${this.df.is_web_form ? "" : "small"}"></span>
					<span class="help-area"></span>
				</label>
				<p class="help-box small text-muted"></p>
			</div>
		</div>`).appendTo(this.parent);
	}
	set_input_areas() {
		this.label_area = this.label_span = this.$wrapper.find(".label-area").get(0);
		this.input_area = this.$wrapper.find(".input-area").get(0);
		this.disp_area = this.$wrapper.find(".disp-area").get(0);
	}
	make_input() {
		super.make_input();
		this.$input.removeClass("form-control");
	}
	get_input_value() {
		return this.input && this.input.checked ? 1 : 0;
	}
	validate(value) {
		return cint(value);
	}
	set_input(value) {
		value = cint(value);
		if(this.input) {
			this.input.checked = (value ? 1 : 0);
		}
		this.last_value = value;
		this.set_mandatory(value);
		this.set_disp_area(value);
	}
	get_popup_description_area() {
		return ".help-area";
	}
};

bcore.ui.form.ControlCheck = ControlCheck;
