export class Control {

	constructor(opts) {
		Object.assign(this, opts);

		this.colspan = this.df.colspan || this.default_colspan;
		this.rowspan = this.df.rowspan || this.default_rowspan;

		this.make();

		// if developer_mode=1, show fieldname as tooltip
		if (bcore.boot.user && bcore.boot.developer_mode === 1 && this.$wrapper) {
			this.$wrapper.attr("title", __(this.df.fieldname));
		}

		if (this.render_input) {
			this.refresh();
		}
	}

	make() {
		this.make_wrapper();
		this.$wrapper
			.attr("data-fieldtype", this.df.fieldtype)
			.attr("data-fieldname", this.df.fieldname)
			.attr("data-colspan", this.colspan)
			.attr("data-rowspan", this.rowspan);
		this.wrapper = this.$wrapper.get(0);
		this.wrapper.fieldobj = this; // reference for event handlers
		if ( this.is_wide !== undefined && this.is_wide ) {
			this.$wrapper.addClass("wide");
		}
	}

	make_wrapper() {
		this.$wrapper = $("<div class='bcore-control'></div>").appendTo(this.parent);

		// alias
		this.wrapper = this.$wrapper;
	}

	toggle(show) {
		this.df.hidden = show ? 0 : 1;
		this.refresh();
	}

	/**
   * returns "Read", "Write" or "None" as strings based on permissions
   * @param {*} explain 
   * @returns {string} "Read", "Write" or "None"
   */
	get_status(explain) {
		if (this.df.get_status) {
			return this.df.get_status(this);
		}

		if ((!this.doctype && !this.docname) || this.df.parenttype === 'Web Form') {
			// like in case of a dialog box
			if (cint(this.df.hidden)) {
				// eslint-disable-next-line
        if (explain) console.log("By Hidden: None");
				return "None";

			} else if (cint(this.df.hidden_due_to_dependency)) {
				// eslint-disable-next-line
        if (explain) console.log("By Hidden Dependency: None");
				return "None";

			} else if (cint(this.df.read_only)) {
				// eslint-disable-next-line
        if (explain) console.log("By Read Only: Read");
				return "Read";

			}

			return "Write";
		}

		var status = bcore.perm.get_field_display_status(this.df,
			bcore.model.get_doc(this.doctype, this.docname), this.perm || (this.frm && this.frm.perm), explain);

		// hide if no value
		if (this.doctype && status === "Read" && !this.only_input
			&& is_null(bcore.model.get_value(this.doctype, this.docname, this.df.fieldname))
			&& !in_list(["HTML", "Image", "Button"], this.df.fieldtype)) {

			// eslint-disable-next-line
      if (explain) console.log("By Hide Read-only, null fields: None");
			status = "None";
		}

		return status;
	}

	refresh() {
		this.disp_status = this.get_status();
		this.$wrapper
			&& this.$wrapper.toggleClass("hide-control hide", this.disp_status == "None")
			&& this.refresh_input
			&& this.refresh_input();

		var value = this.get_value();

		this.show_translatable_button(value);
	}

	show_translatable_button(value) {
		// Disable translation non-string fields or special string fields
		if (!bcore.model
			|| !this.frm
			|| !this.doc
			|| !this.df.translatable
			|| !bcore.model.can_write('Translation')
			|| !value) return;

		// Disable translation in website
		if (!bcore.views || !bcore.views.TranslationManager) return;

		// Already attached button
		if (this.$wrapper.find('.clearfix .btn-translation').length) return;

		const translation_btn =
      `<a class="btn-translation no-decoration text-muted" title="${__('Open Translation')}">
                <i class="fa fa-globe"></i>
            </a>`;

		$(translation_btn)
			.appendTo(this.$wrapper.find('.clearfix'))
			.on('click', () => {
				if (!this.doc.__islocal) {
					new bcore.views.TranslationManager({
						'df': this.df,
						'source_name': value,
						'target_language': this.doc.language,
						'doc': this.doc
					});
				}
			});

	}

	get_doc() {
		return this.doctype && this.docname
      && locals[this.doctype] && locals[this.doctype][this.docname] || {};
	}
  
	get_model_value() {
		if (this.doc) {
			return this.doc[this.df.fieldname];
		}
	}

	set_value(value) {
		return this.validate_and_set_in_model(value);
	}

	parse_validate_and_set_in_model(value, e) {
		if (this.parse) {
			value = this.parse(value);
		}
		return this.validate_and_set_in_model(value, e);
	}

	validate_and_set_in_model(value, e) {
		var me = this;
		if (this.inside_change_event) {
			return Promise.resolve();
		}
		this.inside_change_event = true;
		var set = function (value) {
			me.inside_change_event = false;
			return bcore.run_serially([
				() => me.set_model_value(value),
				() => {
					me.set_mandatory && me.set_mandatory(value);

					if (me.df.change || me.df.onchange) {
						// onchange event specified in df
						return (me.df.change || me.df.onchange).apply(me, [e]);
					}
				}
			]);
		};

		value = this.validate(value);
		if (value && value.then) {
			// got a promise
			return value.then((value) => set(value));
		} else {
			// all clear
			return set(value);
		}
	}

	get_value() {
		if (this.get_status() === 'Write') {
			return this.get_input_value ?
				(this.parse ? this.parse(this.get_input_value()) : this.get_input_value()) :
				undefined;
		} else {
			return this.value || undefined;
		}
	}

	set_model_value(value) {
		if (this.frm) {
			this.last_value = value;
			return bcore.model.set_value(this.doctype, this.docname, this.df.fieldname,
				value, this.df.fieldtype);
		} else {
			if (this.doc) {
				this.doc[this.df.fieldname] = value;
			}
			this.set_input(value);
			return Promise.resolve();
		}
	}

	set_focus() {
		if (this.$input) {
			this.$input.get(0).focus();
			return true;
		}
	}

	set_popup_description() {
		if (!this.df.description) return;

		if (this.$wrapper.find('.description-popover').length) return;

		const popover = this.get_popup_template();

		$(popover.popover)
			.appendTo(this.$wrapper.find(this.get_popup_description_area()))
			.popover({
				html: true,
				template: popover.template,
				trigger: "hover",
				title: "Help",
				content: this.df.description,
				placement: "top",
				container: "body"
			});
	}

	get_popup_description_area() {
		return ".clearfix";
	}
  
	get_popup_template() {
		return {
			template: `<div class="popover help-popover z-20" role="tooltip">
                    <div class="arrow"></div>
                    <h3 class="popover-title text-muted"></h3>
                    <div class="popover-content text-muted">
                    </div>
                </div>`,
			popover: `<a class="description-popover no-decoration c-semantic-info"
					data-toggle="popover">
					${bcore.load_icon("info-circle", "", "var(--text-size-2h)", 2)}
                </a>`
		};
	}
}

bcore.ui.form.Control = Control;