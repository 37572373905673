export class ControlCurrency extends bcore.ui.form.ControlFloat {
	format_for_input(value, label) {
		let formatted_value = format_number(value, this.get_number_format(), label ? this.get_display_precision(): this.get_precision());
		return isNaN(parseFloat(value)) ? "" : formatted_value;
	}

	get_precision() {
		// round based on field precision or float precision, else don't round
		return this.df.precision || cint(bcore.boot.sysdefaults.currency_precision || 2, null);
	}

	get_display_precision() {
		// round based on field precision or display float precision, else don't round
		return this.df.display_precision || cint(bcore.boot.sysdefaults.display_currency_precision || 2, null);
	}
}

bcore.ui.form.ControlCurrency = ControlCurrency;
