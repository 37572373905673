import { ControlInput } from "./base_input";

export class ControlData extends ControlInput {

	constructor(opts) {
		super(opts);
	}

	get html_element() { return "input" }

	get input_type() { return "text" }

	make_input() {
		if(this.$input) return;
		this.$input = $("<"+ this.html_element +">")
			.attr("type", this.input_type)
			.attr("autocomplete", "off")
			.addClass("input-with-feedback form-control")
			.prependTo(this.input_area);

		if (in_list(['Data', 'Link', 'Dynamic Link', 'Password', 'Select', 'Read Only', 'Attach', 'Attach Image'], this.df.fieldtype)) {
			this.$input.attr("maxlength", this.df.length || 140);
		}

		if (this.df.options === "URL") {
			$(this.input_area).addClass("form-control-url");
			$(`<span>
				<a class="btn-open no-decoration hide" title="${__("Open Link")}" target="_blank">
					<i class="fa fa-external-link"></i>
				</a>
			</span>`).appendTo(this.input_area);
		}

		this.set_input_attributes();
		this.input = this.$input.get(0);
		this.has_input = true;
		this.bind_change_event();
		this.bind_open_link_event();
		this.bind_focusout();
		this.setup_autoname_check();

		// somehow this event does not bubble up to document
		// after v7, if you can debug, remove this
	}

	setup_autoname_check() {
		if (!this.df.parent) return;
		this.meta = bcore.get_meta(this.df.parent);
		if (this.meta && ((this.meta.autoname
			&& this.meta.autoname.substr(0, 6)==='field:'
			&& this.meta.autoname.substr(6) === this.df.fieldname) || this.df.fieldname==='__newname') ) {
			this.$input.on('keyup', () => {
				this.set_description('');
				if (this.doc && this.doc.__islocal) {
					// check after 1 sec
					let timeout = setTimeout(() => {
						// clear any pending calls
						if (this.last_check) clearTimeout(this.last_check);

						// check if name exists
						bcore.db.get_value(this.doctype, this.$input.val(),
							'name', (val) => {
								if (val) {
									this.set_description(__('{0} already exists. Select another name', [val.name]));
								}
							},
							this.doc.parenttype
						);
						this.last_check = null;
					}, 1000);
					this.last_check = timeout;
				}
			});
		}
	}
	set_input_attributes() {
		this.$input
			.attr("data-fieldtype", this.df.fieldtype)
			.attr("data-fieldname", this.df.fieldname)
			.attr("placeholder", this.df.placeholder || "");
		if(this.doctype) {
			this.$input.attr("data-doctype", this.doctype);
		}
		if(this.df.input_css) {
			this.$input.css(this.df.input_css);
		}
		if(this.df.input_class) {
			this.$input.addClass(this.df.input_class);
		}
	}
	set_input(value) {
		this.last_value = this.value;
		this.value = value;
		this.set_formatted_input(value);
		this.set_disp_area(value);
		this.set_mandatory && this.set_mandatory(value);
	}
	set_formatted_input(value) {
		this.$input && this.$input.val(this.format_for_input(value));
	}
	get_input_value() {
		return this.$input ? this.$input.val() : undefined;
	}
	format_for_input(val) {
		return val==null ? "" : val;
	}
	bind_open_link_event() {
		let me = this;
		if(me.df.options != 'URL') return;
		me.$input.on('blur', function() {
			me.show_open_link_button();
		});

		setTimeout(() => {
			// Show open link button when loading the page
			me.show_open_link_button();
		}, 300);
	}
	show_open_link_button() {
		let me = this;
		let link = me.get_input_value();

		if (link && validate_url(link)) {
			me.$input_wrapper && me.$input_wrapper.find(".btn-open") &&
			me.$input_wrapper.find(".btn-open").hasClass("hide") && me.$input_wrapper.find(".btn-open").removeClass("hide");
			me.$input_wrapper.find(".btn-open") && me.$input_wrapper.find(".btn-open").attr("href",
				link.match('https://') || link.match('http://') || link.match('ftp://') ? link : "//" + link);
		} else {
			me.$input_wrapper && me.$input_wrapper.find(".btn-open") &&
			!me.$input_wrapper.find(".btn-open").hasClass("hide") && me.$input_wrapper.find(".btn-open").addClass("hide");
		}
	}
	validate(v) {
		if(this.df.is_filter) {
			return v;
		}
		if(this.df.options == 'Phone') {
			if(v+''=='') {
				return '';
			}
			var v1 = '';
			// phone may start with + and must only have numbers later, '-' and ' ' are stripped
			v = v.replace(/ /g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '');

			// allow initial +,0,00
			if(v && v.substr(0,1)=='+') {
				v1 = '+'; v = v.substr(1);
			}
			if(v && v.substr(0,2)=='00') {
				v1 += '00'; v = v.substr(2);
			}
			if(v && v.substr(0,1)=='0') {
				v1 += '0'; v = v.substr(1);
			}
			v1 += v + '';

			let formatted = bcore.utils.format_phone(v1, this.frm ? this.frm.doc : this.doc);

			if (!formatted) {
				bcore.throw(__("Invalid {0}: {1}", [__(this.df.label), v]));
			}

			return formatted;
		} else if(this.df.options == 'URL') {
			if(v+''=='') {
				return '';
			}
			if(!validate_url(v)) {
				bcore.throw(__("Invalid URL"));
			}
			return v;
		} else if(this.df.options == 'Email') {
			if(v+''=='') {
				return '';
			}

			var email_list = bcore.utils.split_emails(v);
			if (!email_list) {
				// invalid email
				return '';
			} else {
				let invalid_emails = [];
				email_list.forEach(function(email) {
					if (!validate_email(email)) {
						invalid_emails.push(email);
					}
				});

				if (invalid_emails.length !== 0) {
					bcore.throw(__("Invalid Email: {0}", [invalid_emails.join(", ")]));
				}

				return v;
			}

		} else {
			return v;
		}
	}
}

bcore.ui.form.ControlData = ControlData;
