import { ControlCode } from './code';


export class ControlMarkdownEditor extends ControlCode {
	get editor_class() { return 'markdown' }

	make_ace_editor() {
		super.make_ace_editor();

		this.ace_editor_target.wrap(`<div class="${this.editor_class}-container">`);
		this.markdown_container = this.$input_wrapper.find(`.${this.editor_class}-container`);

		this.showing_preview = false;
		this.preview_toggle_btn = $(`<button class="btn pv-1h ph-1 f-1h ${this.editor_class}-toggle">${__('Preview')}</button>`)
			.click(e => {
				if (!this.showing_preview) {
					this.update_preview();
				}

				const $btn = $(e.target);
				this.markdown_preview.toggle(!this.showing_preview);
				this.ace_editor_target.toggle(this.showing_preview);

				this.showing_preview = !this.showing_preview;

				$btn.text(this.showing_preview ? __('Edit') : __('Preview'));
			});
		this.markdown_container.prepend(this.preview_toggle_btn);

		this.markdown_preview = $(`<div class="${this.editor_class}-preview b-solid round-1">`).hide();
		this.markdown_container.append(this.markdown_preview);
	}

	set_language() {
		this.df.options = 'Markdown';
		super.set_language();
	}

	update_preview() {
		const value = this.get_value() || "";
		this.markdown_preview.html(bcore.markdown(value));
	}

	async set_formatted_input(value) {
		await super.set_formatted_input(value);
		this.update_preview();
	}
}

bcore.ui.form.ControlMarkdownEditor = ControlMarkdownEditor;
