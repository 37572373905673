import { Control } from "./base_control";
export class ControlInput extends Control {

	constructor(opts) {
		super(opts);
	}

	get horizontal() { return true }
	
	make() {
		// parent element
		super.make();
		this.set_input_areas();

		// set description
		this.set_max_width();
	}
	make_wrapper() {
		if(this.only_input) {
			this.$wrapper = $('<div class="form-group bcore-control flex-col min-h-40px">').appendTo(this.parent);
		} else {
			this.$wrapper = $('<div class="bcore-control flex-col min-h-40px">\
				<div class="form-group flex-col flex-fluid">\
					<label class="control-label flex-static flex-row ai-center gap-2h"><span></span><span class="help-area"></span></label>\
					<label class="control-secondary-label flex-static flex-row ai-center"></label>\
					<div class="control-input-wrapper flex-fluid flex-col">\
						<div class="control-input flex-col flex-fluid"></div>\
						<div class="control-value flex-fluid like-disabled-input form-control d-flex ai-center" style="display: none;"></div>\
						<p class="help-box small text-muted hidden-xs"></p>\
					</div>\
				</div>\
			</div>').appendTo(this.parent);
		}
	}
	toggle_label(show) {
		this.$wrapper.find(".control-label").toggleClass("hide", !show);
	}
	toggle_description(show) {
		this.$wrapper.find(".help-box").toggleClass("hide", !show);
	}
	toggle_secondary_label(show) {
		this.$wrapper.find(".control-secondary-label").toggleClass("hide", !show);
	}
	set_input_areas() {
		if(this.only_input) {
			this.input_area = this.wrapper;
		} else {
			this.label_area = this.label_span = this.$wrapper.find(".control-label > span").get(0);
			this.$secondary_label = this.$wrapper.find(".control-secondary-label");
			this.help_area = this.$wrapper.find("label .help-area");
			this.input_area = this.$wrapper.find(".control-input").get(0);
			this.$input_wrapper = this.$wrapper.find(".control-input-wrapper");
			// keep a separate display area to rendered formatted values
			// like links, currencies, HTMLs etc.
			this.disp_area = this.$wrapper.find(".control-value").get(0);
		}
	}

	get_popup_description_area() {
		return ".help-area";
	}

	set_max_width() {
		if(this.horizontal) {
			this.$wrapper.addClass("input-max-width");
			this.$wrapper.addClass("wide");
		}
	}

	/**
	 * update input value, label, description
	 * display (show/hide/read-only),
	 * mandatory style on refresh
	 */
	refresh_input() {
		var me = this;
		var make_input = function() {
			if (!me.has_input) {
				me.make_input();
				if (me.df.on_make) {
					me.df.on_make(me);
				}
			}
		};

		var update_input = function() {
			if (me.doctype && me.docname) {
				me.set_input(me.value);
			} else {
				me.set_input(me.value || null);
			}
		};

		if (me.disp_status != "None") {
			// refresh value
			if (me.frm) {
				me.value = bcore.model.get_value(me.doctype, me.docname, me.df.fieldname);
			} else if (me.doc) {
				me.value = me.doc[me.df.fieldname];
			}

			if (me.can_write()) {
				me.disp_area && $(me.disp_area).toggle(false);
				$(me.input_area).toggle(true);
				me.$wrapper.toggleClass("disabled", false);
				me.$input && me.$input.prop("disabled", false);
				make_input();
				update_input();
			} else {
				if (me.only_input) {
					make_input();
					update_input();
				} else {
					$(me.input_area).toggle(false);
					if (me.disp_area) {
						me.set_disp_area(me.value);
						$(me.disp_area).toggle(true);
					}
				}
				me.$wrapper.toggleClass("disabled", true);
				me.$input && me.$input.prop("disabled", true);
			}

			me.set_description();
			me.set_label();
			me.set_mandatory(me.value);
			me.set_bold();
		}

		me.$wrapper.toggleClass("has-data", !!me.value);
	}

	can_write() {
		return this.disp_status == "Write";
	}

	set_disp_area(value) {
		if(in_list(["Currency", "Int", "Float"], this.df.fieldtype)
			&& (this.value === 0 || value === 0)) {
			// to set the 0 value in readonly for currency, int, float field
			value = 0;
		} else {
			value = this.value || value;
		}
		if (this.df.fieldtype === 'Data') {
			value = bcore.utils.escape_html(value);
		}
		let doc = this.doc || (this.frm && this.frm.doc);
		let display_value = bcore.format(value, this.df, { no_icon: true, inline: true }, doc);
		this.disp_area && $(this.disp_area).html(display_value);
	}

	bind_change_event() {
		var me = this;
		this.$input && this.$input.on("change", this.change || function(e) {
			me.parse_validate_and_set_in_model(me.get_input_value(), e);
		});
		this.$input && this.$input.on("change", function(e) {
			me.$wrapper.toggleClass("has-data", !!me.get_input_value());
		});
		this.$input && this.$input.on("keyup", function(e) {
			me.$wrapper.toggleClass("user-typing", !!e.target.value);
		});
		this.$input && this.$input.on("blur", function(e) {
			me.$wrapper.removeClass("user-typing");
		});
	}
	bind_focusout() {
		// on touchscreen devices, scroll to top
		// so that static navbar and page head don't overlap the input
		if (bcore.dom.is_touchscreen()) {
			var me = this;
			this.$input && this.$input.on("focusout", function() {
				if (bcore.dom.is_touchscreen()) {
					bcore.utils.scroll_to(me.$wrapper, true);
				}
			});
		}
	}
	set_label(label) {
		if(label) this.df.label = label;

		if(this.only_input || this.df.label==this._label)
			return;

		var icon = "";
		this.label_span.innerHTML = (icon ? '<i class="'+icon+'"></i> ' : "") +
			__(this.df.label)  || "&nbsp;";
		this._label = this.df.label;
	}

	set_secondary_label(label) {
		this.$secondary_label.empty();
		
		if (this.only_input)
			return;

		this.$secondary_label.text(__(label));
	}

	set_description(description) {
		this.set_empty_description();

		if (this.only_input) {
			return;
		}

		if (this.df.description) {
			this.set_popup_description();
		}

		if (description) {
			this.set_new_description(description);
		}
	}
	set_new_description(description) {
		this.$wrapper.find(".help-box").html(description);
	}
	set_empty_description() {
		this.$wrapper.find(".help-box").html("");
	}
	set_mandatory(value) {
		this.$wrapper.toggleClass("has-error", (this.df.reqd && is_null(value)) ? true : false);
	}
	set_bold() {
		if(this.$input) {
			this.$input.toggleClass("bold", !!(this.df.bold || this.df.reqd));
		}
		if(this.disp_area) {
			$(this.disp_area).toggleClass("bold", !!(this.df.bold || this.df.reqd));
		}
	}
}

bcore.ui.form.ControlInput = ControlInput;