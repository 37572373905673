import { ControlData } from "./data";
import moment from "moment";

export class ControlDate extends ControlData {
	make_input() {
		super.make_input();
		this.set_date_options();
		this.set_datepicker();
		this.set_t_for_today();
	}
	set_formatted_input(value) {
		super.set_formatted_input(value);
		if (!this.datepicker) return;
		if (!value) {
			this.datepicker.clear();
			return;
		} else if (value === "Today") {
			value = this.get_now_date();
		}

		let should_refresh = this.last_value && this.last_value !== value;

		if (!should_refresh) {
			if(this.datepicker.selectedDates.length > 0) {
				// if date is selected but different from value, refresh
				const selected_date =
					moment(this.datepicker.selectedDates[0])
						.format(this.date_format);

				should_refresh = selected_date !== value;
			} else {
				// if datepicker has no selected date, refresh
				should_refresh = true;
			}
		}

		if(should_refresh) {
			this.datepicker.selectDate(bcore.datetime.str_to_obj(value));
		}
	}
	set_date_options() {
		// webformTODO:
		let sysdefaults = bcore.boot.sysdefaults;

		let lang = 'en';
		bcore.boot.user && (lang = bcore.boot.user.language);
		if(!$.fn.datepicker.language[lang]) {
			lang = 'en';
		}

		let date_format = sysdefaults && sysdefaults.date_format
			? sysdefaults.date_format : 'yyyy-mm-dd';

		let now_date = new Date();

		this.viewportRoot = this.$wrapper.scrollParent().get(0);
		this.intersectObserver = new IntersectionObserver((entries) => {
			this.field_is_visible = entries[0].isIntersecting === true;
		}, {
			root: this.viewportRoot
		});

		this.today_text = __("Today");
		this.date_format = bcore.defaultDateFormat;
		this.datepicker_options = {
			language: lang,
			autoClose: true,
			todayButton: true,
			dateFormat: date_format,
			startDate: now_date,
			keyboardNav: false,
			onSelect: () => {
				this.$input.trigger('change');
			},
			onShow: () => {
				this.is_open = true;
				this.datepicker.$datepicker
					.find('.datepicker--button:visible')
					.text(this.today_text);

				this.intersectObserver.observe(this.$wrapper[0]);
				this.datepicker.$datepicker.parent().css("z-index", 9999);
				this.datepicker.$datepicker.css("z-index", 9999);
				window.requestAnimationFrame(this.update_datepicker_position.bind(this));
			},
			onHide: () => {
				this.is_open = false;
				this.intersectObserver.disconnect();
			}
		};
	}
	update_datepicker_position() {
		if(!this.frm) return;
		if (this.field_is_visible) {
			// show datepicker above or below the input
			// based on scroll position
			var window_height = $(window).height();
			var window_scroll_top = $(window).scrollTop();
			var el_offset_top = this.$input.offset().top + 280;
			var position = 'top left';
			if(window_height + window_scroll_top >= el_offset_top) {
				position = 'bottom left';
			}
			// don't update date picker position unless datepicker postion changed.
			if (this.last_datepicker_offset != el_offset_top) {
				this.datepicker.update('position', position);
			}
			this.last_datepicker_offset = el_offset_top;
		} else {
			this.datepicker.$datepicker.css("top", -100000);
		}

		if (this.is_open) {
			window.requestAnimationFrame(this.update_datepicker_position.bind(this));
		}
	}
	set_datepicker() {
		this.$input.datepicker(this.datepicker_options);
		this.datepicker = this.$input.data('datepicker');

		// today button didn't work as expected,
		// so explicitly bind the event
		this.datepicker.$datepicker
			.find('[data-action="today"]')
			.click(() => {
				this.datepicker.selectDate(this.get_now_date());
			});
	}
	get_now_date() {
		return bcore.datetime.now_date(true);
	}
	set_t_for_today() {
		var me = this;
		this.$input.on("keydown", function(e) {
			if(e.which===84) { // 84 === t
				if(me.df.fieldtype=='Date') {
					me.set_value(bcore.datetime.nowdate());
				} if(me.df.fieldtype=='Datetime') {
					me.set_value(bcore.datetime.now_datetime());
				} if(me.df.fieldtype=='Time') {
					me.set_value(bcore.datetime.now_time());
				}
				return false;
			}
		});
	}
	parse(value) {
		if(value) {
			return bcore.datetime.user_to_str(value);
		}
	}
	format_for_input(value) {
		if(value) {
			return bcore.datetime.str_to_user(value);
		}
		return "";
	}
	validate(value) {
		if(value && !bcore.datetime.validate(value)) {
			let sysdefaults = bcore.sys_defaults;
			let date_format = sysdefaults && sysdefaults.date_format
				? sysdefaults.date_format : 'yyyy-mm-dd';
			bcore.msgprint(__("Date {0} must be in format: {1}", [value, date_format]));
			return '';
		}
		return value;
	}
}

bcore.ui.form.ControlDate = ControlDate;