import { ControlInt } from "./int";

export class ControlFloat extends ControlInt {
	bind_change_event() {
		let me = this;
		this.$input && this.$input.on("change", this.change || function(e) {
			me.parse_validate_and_set_in_model(me.get_input_value(), e);
		});

		this.$input && this.$input.on("click", function() {
			// When the input in clicked, restore the original value with precision
			me.set_formatted_input(me.get_value(), false);
		});

		this.$input && this.$input.on("blur", function() {
			// Set the formatted value with display precision
			me.set_formatted_input(me.get_input_value(), true);
		});
	}

	parse(value) {
		value = this.eval_expression(value);
		return isNaN(parseFloat(value)) ? null : flt(value, this.get_precision());
	}

	format_for_input(value, label) {
		let number_format = this.df.fieldtype==="Float" && this.df.options && this.df.options.trim() ? this.get_number_format() : null;
		let formatted_value = format_number(value, number_format, label ? this.get_display_precision(): this.get_precision());
		return isNaN(parseFloat(value)) ? "" : formatted_value;
	}

	get_number_format() {
		var currency = bcore.meta.get_field_currency(this.df, this.get_doc());
		return get_number_format(currency);
	}

	get_precision() {
		// round based on field precision or float precision, else don't round
		return this.df.precision || cint(bcore.boot.sysdefaults.float_precision, null);
	}

	get_display_precision() {
		// round based on field precision or display float precision, else don't round
		return this.df.display_precision || cint(bcore.boot.sysdefaults.display_float_precision, null);
	}

	set_formatted_input(value, label=true) {
		this.$input && this.$input.val(this.format_for_input(value, label));
	}

	get_value() {
		return this.value || null;
	}
}

export const ControlPercent = ControlFloat;

bcore.ui.form.ControlPercent = ControlPercent;
bcore.ui.form.ControlFloat = ControlFloat;
