import { ControlData } from "./data";

export class ControlInt extends ControlData {

	make_input() {
		var me = this;
		super.make_input();
		this.$input
			// .addClass("text-right")
			.on("focus", function() {
				setTimeout(function() {
					if(!document.activeElement) return;
					document.activeElement.value
						= me.validate(document.activeElement.value);
					document.activeElement.select && document.activeElement.select();
				}, 100);
				return false;
			});
	}

	eval_expression(value) {
		if (typeof value === 'string') {
			if (value.match(/^[0-9+\-/* ]+$/)) {
				// If it is a string containing operators
				try {
					return (new Function(`return ${value}`))();
				} catch (e) {
					// bad expression
					return value;
				}
			}
		}
		return value;
	}

	parse(value) {
		return cint(this.eval_expression(value), null);
	}
}

bcore.ui.form.ControlInt = ControlInt;