// special features for link
// buttons
// autocomplete
// link validation
// custom queries
// add_fetches
import Awesomplete from 'awesomplete';
import { ControlData } from './data';


export class ControlLink extends ControlData {
	make_input() {
		var me = this;
		// line-height: 1 is for Mozilla 51, shows extra padding otherwise
		$('<div class="link-field ui-fron rel w-100p">\
			<input type="text" class="input-with-feedback form-control">\
			<span class="link-btn">\
				<a class="btn-open no-decoration" title="' + __("Open Link") + '">\
					<i class="octicon octicon-arrow-right"></i></a>\
			</span>\
		</div>').prependTo(this.input_area);
		this.$input_area = $(this.input_area);
		this.$input = this.$input_area.find('input');
		this.$link = this.$input_area.find('.link-btn');
		this.$link_open = this.$link.find('.btn-open');
		this.set_input_attributes();
		this.$input.on("focus", function () {
			setTimeout(function () {
				if (me.$input.val() && me.get_options()) {
					let doctype = me.get_options();
					let name = me.get_input_value();
					me.$link.toggle(true);
					me.$link_open.attr('href', bcore.utils.get_form_link(doctype, name));
				}

				if (!me.$input.val()) {
					me.reset_value();
					me.reset_fetch_values(me.df, me.docname);
					me.$input.val("").trigger("input");
				}
			}, 500);
		});
		this.$input.on("blur", function () {
			// if this disappears immediately, the user's click
			// does not register, hence timeout
			setTimeout(function () {
				me.$link.toggle(false);
			}, 500);
		});
		this.$input.attr('data-target', this.df.options);
		this.input = this.$input.get(0);
		this.has_input = true;
		this.translate_values = true;
		this.data_value = null;
		this.setup_buttons();
		this.setup_awesomeplete();
	}

	get_options() {
		return this.df.options;
	}

	get_reference_doctype() {
		// this is used to get the context in which link field is loaded
		if (this.doctype) return this.doctype;
		else {
			return bcore.get_route && bcore.get_route()[0] === 'List' ? bcore.get_route()[1] : null;
		}
	}

	setup_buttons() {
		if (this.only_input && !this.with_link_btn) {
			this.$input_area.find(".link-btn").remove();
		}
	}

	set_formatted_input(value) {
		super.set_formatted_input();
		if (!value) return;
		let doctype = this.get_options();
		this.set_data_value(bcore.get_link_title(doctype, value) || value, value);
	}

	set_data_value(link_display, value) {
		if (!this.$input) {
			return;
		}

		this.$input.val(__(link_display));
		this.data_value = value;
	}

	parse_validate_and_set_in_model(value, label, e) {
		if (this.parse) value = this.parse(value, label);
		if (label) {
			this.label = label;
			bcore.add_link_title(this.doctype, value, label);
		}

		return this.validate_and_set_in_model(value, e);
	}

	validate_and_set_in_model(value, e) {
		var me = this;
		if (this.inside_change_event) {
			return Promise.resolve();
		}
		this.inside_change_event = true;
		var set = function (value) {
			me.inside_change_event = false;
			return bcore.run_serially([
				() => me.set_model_value(value),
				() => {
					me.set_mandatory && me.set_mandatory(value);

					if (me.df.change || me.df.onchange) {
						// onchange event specified in df
						bcore.set_link_title(me);
						return (me.df.change || me.df.onchange).apply(me, [e]);
					}
				}
			]);
		};

		value = this.validate(value);
		if (value && value.then) {
			// got a promise
			return value.then((value) => set(value));
		} else {
			// all clear
			return set(value);
		}
	}

	get_input_value() {
		return (this.$input && this.data_value && this.$input.val()) ? this.data_value : "";
	}

	get_label_value() {
		return this.$input ? this.$input.val() : "";
	}

	set_input_label(label) {
		this.$input && this.$input.val(__(label));
	}

	reset_value() {
		if (!this.$input) {
			return;
		}
		this.$input.val("");
		this.data_value = null;
	}

	open_advanced_search() {
		var doctype = this.get_options();
		if (!doctype) return;
		new bcore.ui.form.LinkSelector({
			doctype: doctype,
			target: this,
			txt: this.get_input_value()
		});
		return false;
	}

	new_doc() {
		var doctype = this.get_options();
		var me = this;

		if (!doctype) return;

		bcore.route_options = {};

		// set values to fill in the new document
		if (this.df.get_route_options_for_new_doc) {
			bcore.route_options = this.df.get_route_options_for_new_doc(this);
		}

		// partially entered name field
		bcore.route_options.name_field = this.get_label_value();

		// reference to calling link
		bcore._from_link = this;
		bcore._from_link_scrollY = $(document).scrollTop();

		bcore.ui.form.make_quick_entry(doctype, (doc) => {
			return me.set_value(doc.name);
		});

		return false;
	}

	/**
	 * Used to find any offset that could cause the awesomplete dropdown to detach from
	 * input since we are fixing its position on screen.
	 */
	find_transform() {
		let $el = this.$wrapper;
		this.transform_offset = { top: 0, left: 0 };
		let count = 0; // escape hatch
		while ($el && $el.length > 0 && count < 400) {
			count++;
			const el = $el[0];
			const style = getComputedStyle(el);
			const transform = style.transform;
			if (transform && transform != "none") {
				// If we have a transform assume translateX and translateY
				// as they would be the most common for centering content on the screen
				// So our offset would be top and left bounds of this element.

				const transform_value = transform.match(/-?\d+(\.\d+)?/g);
				const dx = parseFloat(transform_value[4]);
				const dy = parseFloat(transform_value[5]);

				// Skip zero transformed elements
				if ( dx != 0 || dy !=0 ) {
					this.transformed_parent = el;
					break;
				}
			}

			$el = $el.parent();

			if ($el.is('body')) {
				break;
			}
		}
	}

	adjust_dropdown() {
		const me = this;
		if (me.autocomplete_open) {
			if (me.is_visible) {
				const wrapperBounds = me.$wrapper[0].getBoundingClientRect();
				const top = wrapperBounds.bottom;
				const left = wrapperBounds.left;
				if (this.transformed_parent) {
					this.transform_offset = this.transformed_parent.getBoundingClientRect();
				}
				const css = {
					"position": "fixed",
					"top": top - me.transform_offset.top,
					"left": left - me.transform_offset.left,
					"width": me.$wrapper.width()
				};
				me.$wrapper.find('ul').css(css);
			} else {
				// When user scrolls dropdown out of its parent scrollable view while open
				// just move it completely out of the viewport so we don't have a floating
				// dropdown.
				me.$wrapper.find('ul').css({
					"top": -100000
				});
			}

			window.requestAnimationFrame(this.adjust_dropdown.bind(this));
		} else {
			this.intersectObserver.disconnect();
		}
	}

	setup_awesomeplete() {
		var me = this;

		this.$input.cache = {};

		this.transform_init = false;

		this.awesomplete = new Awesomplete(me.input, {
			minChars: 0,
			maxItems: 99,
			autoFirst: true,
			list: [],
			replace: function (suggestion) {
				// Override Awesomeplete replace function as it is used to set the input value
				// https://github.com/LeaVerou/awesomplete/issues/17104#issuecomment-359185403
				this.input.value = suggestion.label || suggestion.value;
			},
			data: function (item) {
				return {
					label: item.label || item.value,
					value: item.value
				};
			},
			filter: function () {
				return true;
			},
			item: function (item) {
				var d = this.get_item(item.value);
				if (!d.label) {
					d.label = d.value;
				}

				var _label = (me.translate_values) ? __(d.label) : d.label;
				var html = d.html || "<strong>" + _label + "</strong>";
				if (d.description && d.value !== d.description) {
					html += '<br><span class="small">' + __(d.description) + '</span>';
				}
				return $('<li></li>')
					.data('item.autocomplete', d)
					.prop('aria-selected', 'false')
					.html('<a><p>' + html + '</p></a>')
					.get(0);
			},
			sort: function () {
				return 0;
			}
		});

		// monitor this control visibility to allow hiding dropdown
		// when user scrolls input away from scrollable view.
		this.viewportRoot = this.$wrapper.scrollParent().get(0);
		this.intersectObserver = new IntersectionObserver((entries) => {
			me.is_visible = entries[0].isIntersecting === true;
		}, {
			root: this.viewportRoot
		});

		this.$input.on("input", bcore.utils.debounce(function (e) {
			var doctype = me.get_options();
			if (!doctype) return;
			if (!me.$input.cache[doctype]) {
				me.$input.cache[doctype] = {};
			}

			var term = e.target.value;

			if (me.$input.cache[doctype][term] != null) {
				// immediately show from cache
				me.awesomplete.list = me.$input.cache[doctype][term];
			}
			var args = {
				'txt': term,
				'doctype': doctype,
				'ignore_user_permissions': me.df.ignore_user_permissions,
				'reference_doctype': me.get_reference_doctype() || ""
			};

			me.set_custom_query(args);

			bcore.call({
				type: "POST",
				method: 'bcore.desk.search.search_link',
				no_spinner: true,
				args: args,
				callback: function (r) {
					if (!me.$input.is(":focus")) {
						return;
					}
					r.results = me.merge_duplicates(r.results);

					// show filter description in awesomplete
					if (args.filters) {
						let filter_string = me.get_filter_description(args.filters);
						if (filter_string) {
							r.results.push({
								html: `<span class="text-muted">${filter_string}</span>`,
								value: '',
								action: () => { }
							});
						}
					}

					if (!me.df.only_select) {
						if (bcore.model.can_create(doctype)) {
							// new item
							r.results.push({
								label: "<span class='text-primary link-option'>"
									+ "<i class='fa fa-plus' style='margin-right: 5px;'></i> "
									+ __("Create a new {0}", [__(me.get_options())])
									+ "</span>",
								value: "create_new__link_option",
								action: me.new_doc
							});
						}
						// advanced search

						if (locals && locals['DocType']) {
							// not applicable in web forms
							r.results.push({
								label: "<span class='text-primary link-option'>"
									+ "<i class='fa fa-search' style='margin-right: 5px;'></i> "
									+ __("Advanced Search")
									+ "</span>",
								value: "advanced_search__link_option",
								action: me.open_advanced_search
							});
						}
					}
					me.$input.cache[doctype][term] = r.results;
					me.awesomplete.list = me.$input.cache[doctype][term];
				}
			});
		}, 500));

		this.$input.on("blur", function () {
			if (me.selected) {
				me.selected = false;
				return;
			}
			let value = me.get_input_value();
			let label = me.get_label_value();

			if (value !== me.last_value || me.label !== label) {
				me.parse_validate_and_set_in_model(value, label);
			}
		});


		this.$input.on("awesomplete-open", function () {
			// me.$wrapper.css({ "z-index": 100 });
			if ( !me.transform_init ) {
				me.transform_init = true;
				me.find_transform();
			}
			me.autocomplete_open = true;
			// Track visibility. Needs to be reattached on every open event.
			me.intersectObserver.observe(me.$input.get(0));
			// update dropdown position on animation frame(as fast as possible)
			window.requestAnimationFrame(me.adjust_dropdown.bind(me));

			me.$wrapper.find('ul').css({
				"z-index": 100
			});
		});

		this.$input.on("awesomplete-close", function () {
			// me.$wrapper.css({ "z-index": 1 });
			me.autocomplete_open = false;
		});

		this.$input.on("awesomplete-select", function (e) {
			var o = e.originalEvent;
			var item = me.awesomplete.get_item(o.text.value);

			me.autocomplete_open = false;

			// prevent selection on tab
			var TABKEY = 9;
			if (e.keyCode === TABKEY) {
				e.preventDefault();
				me.awesomplete.close();
				return false;
			}

			if (item.action) {
				item.value = "";
				item.label = "";
				item.action.apply(me);
			}

			// if remember_last_selected is checked in the doctype against the field,
			// then add this value
			// to defaults so you do not need to set it again
			// unless it is changed.
			if (me.df.remember_last_selected_value) {
				bcore.boot.user.last_selected_values[me.df.options] = item.value;
			}

			me.parse_validate_and_set_in_model(item.value, item.label);
		});

		this.$input.on("awesomplete-selectcomplete", function (e) {
			var o = e.originalEvent;
			if (o.text.value.indexOf("__link_option") !== -1) {
				me.reset_value();
				me.reset_fetch_values(me.df, me.docname);
			}
		});
	}

	merge_duplicates(results) {
		// in case of result like this
		// [{value: 'Manufacturer 1', 'description': 'mobile part 1'},
		// 	{value: 'Manufacturer 1', 'description': 'mobile part 2'}]
		// suggestion list has two items with same value (docname) & description
		return results.reduce((newArr, currElem) => {
			if (newArr.length === 0) return [currElem];
			let element_with_same_value = newArr.find(e => e.value === currElem.value);
			if (element_with_same_value) {
				element_with_same_value.description += `, ${currElem.description}`;
				return [...newArr];
			}
			return [...newArr, currElem];
		}, []);
		// returns [{value: 'Manufacturer 1', 'description': 'mobile part 1, mobile part 2'}]
	}

	get_filter_description(filters) {
		let doctype = this.get_options();
		let filter_array = [];
		let meta = null;

		bcore.model.with_doctype(doctype, () => {
			meta = bcore.get_meta(doctype);
		});

		// convert object style to array
		if (!Array.isArray(filters)) {
			for (let fieldname in filters) {
				let value = filters[fieldname];
				if (!Array.isArray(value)) {
					value = ['=', value];
				}
				filter_array.push([fieldname, ...value]); // fieldname, operator, value
			}
		} else {
			filter_array = filters;
		}

		// add doctype if missing
		filter_array = filter_array.map(filter => {
			if (filter.length === 3) {
				return [doctype, ...filter]; // doctype, fieldname, operator, value
			}
			return filter;
		});

		function get_filter_description(filter) {
			let doctype = filter[0];
			let fieldname = filter[1];
			let docfield = bcore.meta.get_docfield(doctype, fieldname);
			let label = docfield ? docfield.label : bcore.model.unscrub(fieldname);

			if (filter[3] && Array.isArray(filter[3]) && filter[3].length > 5) {
				filter[3] = filter[3].slice(0, 5);
				filter[3].push('...');
			}

			let value = filter[3] == null || filter[3] === ''
				? __('empty')
				: String(filter[3]);

			return [__(label).bold(), filter[2], value.bold()].join(' ');
		}

		let filter_string = filter_array
			.map(get_filter_description)
			.join(', ');

		return __('Filters applied for {0}', [filter_string]);
	}

	set_custom_query(args) {
		var set_nulls = function (obj) {
			$.each(obj, function (key, value) {
				if (value !== undefined) {
					obj[key] = value;
				}
			});
			return obj;
		};
		if (this.get_query || this.df.get_query) {
			var get_query = this.get_query || this.df.get_query;
			if ($.isPlainObject(get_query)) {
				var filters = null;
				if (get_query.filters) {
					// passed as {'filters': {'key':'value'}}
					filters = get_query.filters;
				} else if (get_query.query) {

					// passed as {'query': 'path.to.method'}
					args.query = get_query;
				} else {

					// dict is filters
					filters = get_query;
				}

				if (filters) {
					filters = set_nulls(filters);

					// extend args for custom functions
					$.extend(args, filters);

					// add "filters" for standard query (search.py)
					args.filters = filters;
				}
			} else if (typeof (get_query) === "string") {
				args.query = get_query;
			} else {
				// get_query by function
				var q = (get_query)(this.frm && this.frm.doc || this.doc, this.doctype, this.docname);

				if (typeof (q) === "string") {
					// returns a string
					args.query = q;
				} else if ($.isPlainObject(q)) {
					// returns a plain object with filters
					if (q.filters) {
						set_nulls(q.filters);
					}

					// turn off value translation
					if (q.translate_values !== undefined) {
						this.translate_values = q.translate_values;
					}

					// extend args for custom functions
					$.extend(args, q);

					// add "filters" for standard query (search.py)
					args.filters = q.filters;
				}
			}
		}
		if (this.df.filters) {
			set_nulls(this.df.filters);
			if (!args.filters) args.filters = {};
			$.extend(args.filters, this.df.filters);
		}
	}

	validate(value) {
		// validate the value just entered
		if (this.df.options == "[Select]" || this.df.ignore_link_validation) {
			return value;
		}

		return this.validate_link_and_fetch(this.df, this.get_options(), this.docname, value);
	}

	validate_link_and_fetch(df, doctype, docname, value) {
		var me = this;

		if (value) {
			return new Promise((resolve) => {
				var fetch = '';

				if (this.frm && this.frm.fetch_dict[df.fieldname]) {
					fetch = this.frm.fetch_dict[df.fieldname].columns.join(', ');
				}

				return bcore.call({
					method: 'bcore.desk.form.utils.validate_link',
					type: "GET",
					args: {
						'value': value,
						'options': doctype,
						'fetch': fetch
					},
					no_spinner: true,
					callback: function (r) {
						if (r.message == 'Ok') {
							if (r.fetch_values && docname) {
								me.set_fetch_values(df, docname, r.fetch_values);
							}
							resolve(r.valid_value);
						} else {
							me.reset_value();
							me.reset_fetch_values(df, docname);
							resolve("");
						}
					}
				});
			});
		} else {
			me.reset_value();
			me.reset_fetch_values(df, docname);
		}
	}

	set_fetch_values(df, docname, fetch_values) {
		var fl = this.frm.fetch_dict[df.fieldname].fields;
		for (var i = 0; i < fl.length; i++) {
			bcore.model.set_value(df.parent, docname, fl[i], fetch_values[i], df.fieldtype);
		}
	}

	reset_fetch_values(df, docname) {
		let fields = this.frm && this.frm.fetch_dict && this.frm.fetch_dict[df.fieldname] ? this.frm.fetch_dict[df.fieldname].fields : [];

		fields.forEach(field => {
			bcore.model.set_value(df.parent, docname, field, null, df.fieldtype);
		});
	}
}

bcore.ui.form.ControlLink = ControlLink;

if (Awesomplete) {
	Awesomplete.prototype.get_item = function (value) {
		return this._list.find(function (item) {
			return item.value === value;
		});
	};
}
