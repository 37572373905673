import { ControlDate } from "./date";

export class ControlDatetime extends ControlDate {
	set_formatted_input(value) {
		if (this.timepicker_only) return;
		if (!this.datepicker) return;
		if (!value) {
			this.datepicker.clear();
			return;
		} else if (value === "Today") {
			value = this.get_now_date();
		}

		this.$input && this.$input.val(this.format_for_input(value));
	}

	set_date_options() {
		super.set_date_options();
		this.today_text = __("Now");
		this.date_format = bcore.defaultDatetimeFormat;
		$.extend(this.datepicker_options, {
			timepicker: true,
			timeFormat: "hh:ii:ss"
		});
	}

	get_now_date() {
		return bcore.datetime.now_datetime(true);
	}

	parse(value) {
		if (value) {
			value = bcore.datetime.user_to_str(value, false);

			if (!bcore.datetime.is_system_time_zone()) {
				value = bcore.datetime.convert_to_system_tz(value, true);
			}

			return value;
		}
	}

	format_for_input(value) {
		if (!value) return "";

		if (!bcore.datetime.is_system_time_zone()) {
			value = bcore.datetime.convert_to_user_tz(value, true);
		}

		return bcore.datetime.str_to_user(value, false);
	}

	get_user_time_zone() {
		return bcore.boot.time_zone ? bcore.boot.time_zone.user_time_zone : bcore.sys_defaults.time_zone;
	}

	refresh_input() {
		super.refresh_input();

		let timezone = this.get_timezone();
		if (timezone && this.disp_status != "None") {
			this.set_secondary_label(timezone);
		}
	}

	get_timezone() {
		return bcore.sys_defaults.time_zone;
	}
}

bcore.ui.form.ControlDatetime = ControlDatetime;