export class ControlHTMLEditor extends bcore.ui.form.ControlMarkdownEditor {

	get editor_class() {
		return 'html';
	}

	set_language() {
		this.df.options = 'HTML';
		super.set_language();
	}

	update_preview() {
		if (!this.markdown_preview) return;
		let value = this.get_value() || '';
		value = bcore.dom.remove_script_and_style(value);
		this.markdown_preview.html(value);
	}
}

bcore.ui.form.ControlHTMLEditor = ControlHTMLEditor;
