import { ControlData } from "./data";

export class ControlAttach extends ControlData {
	make_input() {
		var me = this;
		this.$input = $('<button class="btn btn-attach btn-secondary">')
			.html(__("Attach"))
			.prependTo(me.input_area)
			.on("click", function() {
				me.on_attach_click();
			});
		this.$value = $(
			`<div class="attached-file flex flex-col justify-between align-center">
				<div class="ellipsis">
					<i class="fa fa-paperclip"></i>
					<a class="attached-file-link" target="_blank"></a>
				</div>
				<div>
					<a class="btn btn-secondary" data-action="reload_attachment">${__('Reload File')}</a>
					<a class="btn btn-secondary" data-action="clear_attachment">${__('Clear')}</a>
				</div>
			</div>`)
			.prependTo(me.input_area)
			.toggle(false);
		this.input = this.$input.get(0);
		this.set_input_attributes();
		this.has_input = true;

		bcore.utils.bind_actions_with_object(this.$value, this);
		this.toggle_reload_button();
	}

	clear_attachment() {
		var me = this;
		if(this.frm) {
			me.parse_validate_and_set_in_model(null);
			me.refresh();
			me.frm.attachments.remove_attachment_by_filename(me.value, function() {
				me.parse_validate_and_set_in_model(null);
				me.clear_temp_attachment(me.value);
				me.refresh();
				me.frm.doc.docstatus == 1 ? me.frm.save('Update') : me.frm.save();
			});
		} else {
			this.dataurl = null;
			this.fileobj = null;
			this.set_input(null);
			this.parse_validate_and_set_in_model(null);
			this.refresh();
		}
	}

	reload_attachment() {
		if (this.file_uploader) {
			this.file_uploader.uploader.upload_files();
		}
	}

	on_attach_click() {
		this.set_upload_options();
		this.file_uploader = new bcore.ui.FileUploader(this.upload_options);
	}

	set_upload_options() {
		let options = {
			allow_multiple: false,
			__islocal: this && this.frm ? this.frm.is_new() : bcore.web_form.is_new,
			on_success: file => {
				this.on_upload_complete(file);
				this.toggle_reload_button();
				((this.frm && this.frm.is_new()) || (bcore.web_form && bcore.web_form.is_new)) && this.save_temp_attachment(file);
			}
		};

		if (this.frm && !this.frm.is_new()) {
			options.doctype = this.frm.doctype;
			options.docname = this.frm.docname;
		}

		if (this.df.options) {
			Object.assign(options, this.df.options);
		}
		this.upload_options = options;
	}

	set_input(value, dataurl) {
		this.value = value;
		if(this.value) {
			this.$input.toggle(false);
			if(this.value.indexOf(",")!==-1) {
				var parts = this.value.split(",");
				var filename = parts[0];
				dataurl = parts[1];
			}
			this.$value.toggle(true).find(".attached-file-link")
				.html(filename || this.value)
				.attr("href", dataurl || this.value);
		} else {
			this.$input.toggle(true);
			this.$value.toggle(false);
		}
	}

	get_value() {
		return this.value || null;
	}

	on_upload_complete(attachment) {
		if (this.frm) {
			this.parse_validate_and_set_in_model(attachment.file_url || attachment.name, attachment.dataurl);
			!this.frm.is_new() && this.frm.attachments.update_attachment(attachment);
			this.frm.doc.docstatus == 1 ? this.frm.save('Update') : this.frm.save();
		}
		this.set_value(attachment.file_url || attachment.name);
	}

	toggle_reload_button() {
		this.$value.find('[data-action="reload_attachment"]')
			.toggle(this.file_uploader && this.file_uploader.uploader.files.length > 0);
	}

	save_temp_attachment(attachment) {
		let __unsaved_attachments = (this.frm ? this.frm.doc.__unsaved_attachments : bcore.web_form.doc.__unsaved_attachments) || [];

		__unsaved_attachments.push({
			"fieldname": this.df.fieldname,
			"filename": attachment.name,
			"attachment": attachment
		});

		if (this.frm) {
			this.frm.doc.__unsaved_attachments = __unsaved_attachments;
		} else {
			bcore.web_form.doc.__unsaved_attachments = __unsaved_attachments;
		}
	}

	clear_temp_attachment(attachment) {
		let __unsaved_attachments = (this.frm ? this.frm.doc.__unsaved_attachments : bcore.web_form.doc.__unsaved_attachments) || [];

		__unsaved_attachments = __unsaved_attachments.filter(
			__unsaved_attachments => __unsaved_attachments.filename !== attachment
		);

		if (this.frm) {
			this.frm.doc.__unsaved_attachments = __unsaved_attachments;
		} else {
			bcore.web_form.doc.__unsaved_attachments = __unsaved_attachments;
		}
	}
}

bcore.ui.form.ControlAttach = ControlAttach;
