import { ControlData } from './data';

export class ControlSignature extends ControlData {

	constructor(opts) {
		super(opts);
		this.saving = false;
		this.loading = false;
	}

	get default_rowspan() { return 4; }
	get is_wide() { return true; }

	make() {
		super.make();

		// make jSignature field
		this.body = $('<div class="signature-field"></div>').appendTo(this.wrapper);

		if (this.body.is(':visible')) {
			this.make_pad();
		} else {
			$(document).on('bcore.ui.Dialog:shown', () => {
				this.make_pad();
			});
		}

		this.img_wrapper = $(`<div class="signature-display">
			<div class="missing-image attach-missing-image">
				<i class="octicon octicon-circle-slash"></i>
			</div></div>`)
			.appendTo(this.wrapper);
		this.img = $("<img class='img-responsive attach-image-display'>")
			.appendTo(this.img_wrapper).toggle(false);

	}
	make_pad() {
		let width = this.body.width();

		if (width > 0 && !this.$pad) {
			this.$pad = this.body.jSignature({
				height: 300,
				width: "100%",
				lineWidth: 3
			}).on('change',
				this.on_save_sign.bind(this));
			this.load_pad();
			this.$reset_button_wrapper = $(`<div class="signature-btn-row d-inline-block f-right">
				<a href="#" type="button" class="signature-reset btn bg-contrast ml-1">
				<i class="glyphicon glyphicon-repeat"></i></a>`)
				.appendTo(this.$pad)
				.on("click", '.signature-reset', () => {
					this.on_reset_sign();
					return false;
				});

			this.$reset_attach_wrapper = $(`<div class="signature-attach-btn-row d-inline-block f-right">
				<a href="#" type="button" class="signature-attach btn bg-contrast">
				<i class="glyphicon glyphicon-link"></i></a>`)
				.appendTo(this.$pad)
				.on("click", '.signature-attach', () => {
					this.make_attachments();
					return false;
				});

		}
	}

	make_attachments() {
		new bcore.ui.FileUploader(this.get_upload_options());
	}

	get_upload_options() {
		var me = this;
		var options = {
			allow_multiple: false,
			as_dataurl: true,
			on_success: file => {
				me.on_upload_complete(file);
			}
		};

		if (this.df.options) {
			Object.assign(options, this.df.options);
		}
		return options;
	}
	on_upload_complete(file) {
		var me = this;
		me.set_my_value(file.dataurl);
		me.set_image(me.get_value());
	}
	refresh_input(e) {
		// prevent to load the second time
		this.make_pad();
		this.$wrapper.find(".control-input").toggle(false);
		this.set_editable(this.get_status() == "Write");
		this.load_pad();
		if (this.get_status() == "Read") {
			$(this.disp_area).toggle(false);
		}
	}
	set_image(value) {
		if (value) {
			$(this.img_wrapper).find(".missing-image").toggle(false);
			this.img.attr("src", value).toggle(true);
		} else {
			$(this.img_wrapper).find(".missing-image").toggle(true);
			this.img.toggle(false);
		}
	}
	load_pad() {
		// make sure not triggered during saving
		if (this.saving) return;
		// get value
		var value = this.get_value();
		// import data for pad
		if (this.$pad) {
			this.loading = true;
			// reset in all cases
			this.$pad.jSignature('reset');
			if (value) {
				// load the image to find out the size, because scaling will affect
				// stroke width
				try {
					this.$pad.jSignature('setData', value);
					this.set_image(value);
				} catch (e) {
					console.log("Cannot set data for signature", value, e);
				}
			}

			this.loading = false;
		}
	}
	set_editable(editable) {
		this.$pad && this.$pad.toggle(editable);
		this.img_wrapper.toggle(!editable);
		if (this.$reset_button_wrapper) {
			this.$reset_button_wrapper.toggle(editable);
			if (editable) {
				this.$reset_button_wrapper.addClass('editing');
				this.$reset_attach_wrapper.addClass('editing');
			}
			else {
				this.$reset_button_wrapper.removeClass('editing');
				this.$reset_attach_wrapper.addClass('editing');
			}
		}
	}

	set_my_value(value) {
		if (this.saving || this.loading) return;
		this.saving = true;
		this.set_value(value);
		this.saving = false;
	}

	get_value() {
		return this.value ? this.value : this.get_model_value();
	}

	// reset signature canvas
	on_reset_sign() {
		this.$pad.jSignature("reset");
		this.set_my_value("");
	}

	// save signature value to model and display
	on_save_sign() {
		if (this.saving || this.loading) return;
		var base64_img = this.$pad.jSignature("getData");
		this.set_my_value(base64_img);
		this.set_image(this.get_value());
	}
}

bcore.ui.form.ControlSignature = ControlSignature;
