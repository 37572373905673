export default class GridRowForm {
	constructor(opts) {
		$.extend(this, opts);
		this.wrapper = $('<div class="form-in-grid fix center-hv xxs-w-95vw xxs-max-h-95vh md-w-80vw md-max-h-90vh lg-w-75vw md-max-h-90vh flex-col elevate-4 b-solid"></div>')
			.appendTo(this.row.wrapper);

	}
	render() {
		var me = this;
		this.make_form();
		this.form_area.empty();

		this.layout = new bcore.ui.form.Layout({
			fields: this.row.docfields,
			body: this.form_area,
			no_submit_on_enter: true,
			frm: this.row.frm,
		});
		this.layout.make();

		this.fields = this.layout.fields;
		this.fields_dict = this.layout.fields_dict;

		this.layout.refresh(this.row.doc);

		// copy get_query to fields
		for(var fieldname in (this.row.grid.fieldinfo || {})) {
			var fi = this.row.grid.fieldinfo[fieldname];
			$.extend(me.fields_dict[fieldname], fi);
		}

		this.toggle_add_delete_button_display(this.wrapper);

		this.row.grid.open_grid_row = this;

		this.set_focus();
	}
	make_form() {
		if(!this.form_area) {
			let template = `<div class="grid-form-heading p-1h bb-solid flex-static bg-secondary">
				<div class="toolbar grid-header-toolbar flex-row gap-1h ai-center">
					<h4 class="panel-title flex-fluid p-0 m-0 f-upper">
						${ __("Editing Row") } #<span class="grid-form-row-index"></span></h4>
					<span class="row-actions flex-row gap-1h flex-static">
						<button class="btn btn-secondary grid-move-row xxs-hide md-d-inline-block">
							${ __("Move") }</button>
						<button class="btn btn-secondary grid-duplicate-row  xxs-hide md-d-inline-block">
							${ __("Duplicate") }</button>
						<button class="btn btn-secondary grid-insert-row">
							${ __("Insert Above") }</button>
						<button class="btn btn-secondary grid-insert-row-below  xxs-hide md-d-inline-block">
							${ __("Insert Below") }</button>
						<button class="btn btn-error grid-delete-row">
							<i class="octicon octicon-trashcan c-white"
								style="padding-bottom: 2px; margin-top: 1px;"></i>
						</button>
					</span>
					<button class="btn btn-secondary flex-static">
						<i class="octicon octicon-check visible-xs" style="padding-bottom: 2px;"></i>
						<span class="hidden-xs octicon octicon-triangle-up"></span></button>
				</div>
			</div>
			<div class="grid-form-body flex-fluid flex-col min-h-0">
				<div class="form-area overflow-auto flex-fluid p-1h"></div>
				<div class="grid-footer-toolbar xxs-hide md-d-flex b-solid sticky bg-secondary z-10 flex-static flex-row">
					<span class="flex-fluid flex-row ai-center gap-2h">
						${bcore.load_icon("keyboard", "p-0 m-0", "2rem", 1)} &ndash;
						<span class="btn-sm btn-secondary">${ __("Ctrl + Up") }</span>, <span class="btn-sm btn-secondary">${ __("Ctrl + Down") }</span>, <span class="btn-sm btn-secondary">${ __("ESC") }</span>
					</span>
				</div>
			</div>`;

			$(template).appendTo(this.wrapper);
			this.form_area = this.wrapper.find(".form-area");
			this.row.set_row_index();
			this.set_form_events();
		}
	}
	set_form_events() {
		var me = this;
		this.wrapper.find(".grid-delete-row")
			.on('click', function() {
				me.row.remove(); return false;
			});
		this.wrapper.find(".grid-insert-row")
			.on('click', function() {
				me.row.insert(true); return false;
			});
		this.wrapper.find(".grid-insert-row-below")
			.on('click', function() {
				me.row.insert(true, true); return false;
			});
		this.wrapper.find(".grid-duplicate-row")
			.on('click', function() {
				me.row.insert(true, true, true); return false;
			});
		this.wrapper.find(".grid-move-row")
			.on('click', function() {
				me.row.move(); return false;
			});
		this.wrapper.find(".grid-append-row")
			.on('click', function() {
				me.row.toggle_view(false);
				me.row.grid.add_new_row(me.row.doc.idx+1, null, true);
				return false;
			});
		this.wrapper.find(".grid-form-heading, .grid-footer-toolbar").on("click", function() {
			me.row.toggle_view();
			return false;
		});
	}
	toggle_add_delete_button_display($parent) {
		$parent.find(".row-actions")
			.toggle(this.row.grid.is_editable());
	}
	refresh_field(fieldname) {
		if(this.fields_dict[fieldname]) {
			this.fields_dict[fieldname].refresh();
			this.layout && this.layout.refresh_dependency();
		}
	}
	set_focus() {
		// wait for animation and then focus on the first row
		let me = this;
		setTimeout(function() {
			if (me.row.frm && me.row.frm.doc.docstatus===0 || !me.row.frm) {
				let first = me.form_area.find("input:first");
				let has_value = first && first[0] && $(first[0]).val();
				if (!has_value && first.length && !in_list(["Date", "Datetime", "Time"], first.attr("data-fieldtype"))) {
					try {
						first.get(0).focus();
					} catch(e) {
						//
					}
				}
			}
		}, 500);
	}
}
